<template>
  <div class="entphases">

    <BlockSubtitle modal="evolution">{{$t('entity.compliance_by_phases')}}</BlockSubtitle>

    <div class="entphases__phase" v-for="(phase, i) in phases" :key="i">

      <div class="entphases__phase-challenges">
        <div class="entphases__phase-challenge">
          <div class="phase-name">
            <div>{{$t(`phases.${i+1}`)}}</div>
          </div>
          <div>
            <Bar :value="phaseAccomplishedPercent(phase)" :color="getPhaseColor(i)"></Bar>
          </div>
        </div>
        <div
          class="entphases__phase-challenge"
          style="cursor: pointer;"
          v-for="(challenge, j) in phase"
          :key="j"
          @click="openModal(challenge)">
          <div>{{$t(`challenges.${challenge.name}`)}}</div>
          <div class="entphases__phase-challenge--imgs">
            <svg v-if="hasChallengeOk(challenge)" viewBox="0 0 54 54" xml:space="preserve">
              <circle :style="{fill:getPhaseColor(i)}" cx="27" cy="27" r="27" />
              <path style="fill:#ffffff;" d="M 11.077191,28.242642 25.029571,41.561788
              43.028148,15.610049 40.79575,14.09963 24.750523,31.675411 14.425762,25.496426 Z"/>
            </svg>
            <img v-else src="/img/icons/phases/no.svg" alt="No">
          </div>
        </div>
      </div>

    </div>

    <teleport to="body">
      <Modal v-if="isModalOpen" @overlayClick="isModalOpen = false">
        <template #header>
          {{modal.title}}
        </template>
        <div>
          <div>{{modal.body}}</div>
          <div v-if="modal.file || modal.url" style="margin-top: 12px; justify-content: center; display: flex">
            <a v-if="modal.url" :href="modal.url" target="_blank">
              <Button :classes="['btn--accent']">{{$t('common.see_link')}}</Button>
            </a>
            <a v-if="modal.file" :href="modal.file" target="_blank">
              <Button :classes="['btn--accent']">{{$t('common.see_attachment')}}</Button>
            </a>
          </div>
        </div>
        <template #footer>
          <Button @onclick="isModalOpen = false">{{$t('common.close')}}</Button>
        </template>
      </Modal>
    </teleport>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'EntityPhasesComponent',
  data() {
    return {
      isModalOpen: false,
      modal: {
        title: '',
        body: '',
        url: null,
        file: null,
      },
    };
  },
  props: {
    report: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters(['challenges', 'entity', 'phaseColors']),
    phases() {
      const blocks = this.challenges.reduce((acc, curr) => {
        const chall = { ...curr, isChecked: false };
        if (this.$t(`challenges_short.${this.entity.type}.${chall.name}`) !== '') {
          // Aggregate only challenges for the current entity type (-> has translation)
          if (Object.prototype.hasOwnProperty.call(acc, chall.phase)) acc[chall.phase].push(chall);
          else acc[chall.phase] = [chall];
        }
        return acc;
      }, {});
      return Object.keys(blocks).sort().map((key) => blocks[key]);
    },
  },
  methods: {
    hasChallengeOk(challenge) {
      const accomplished = this.report.challenges.map((c) => c.challenge);
      return !!accomplished.find((c) => c === challenge.name);
    },
    phaseAccomplishedPercent(phase) {
      const allAccomplished = this.report.challenges.map((c) => c.challenge);
      const phaseAccomplished = phase.reduce((acc, curr) => (
        acc + (allAccomplished.find((c) => c === curr.name) ? 1 : 0)
      ), 0);
      return Math.floor((100 * phaseAccomplished) / phase.length);
    },
    getPhaseColor(phaseIdx) {
      return this.phaseColors[phaseIdx];
    },
    openModal({ name }) {
      this.modal.title = this.$t(`challenges.${name}`);
      this.modal.body = this.$t(`challenges_short.${this.entity.type}.${name}`);

      const accomplished = this.report.challenges.map((c) => c.challenge);
      const entityChallengeIdx = accomplished.findIndex((c) => c === name);
      if (entityChallengeIdx > -1) {
        this.modal.url = this.report.challenges[entityChallengeIdx].url;
        this.modal.file = this.report.challenges[entityChallengeIdx].file;
      } else {
        this.modal.url = null;
        this.modal.file = null;
      }
      this.isModalOpen = true;
    },
  },
};
</script>
