<template>
  <div class="entity-contact">
    <div class="entity-contact__person">
      <div class="entity-contact__name">{{contact.name}}</div>
      <div class="entity-contact__role">{{contact.role}}</div>
    </div>
    <div class="entity-contact__socials">
      <a
        class="entity-contact__social entity-contact__social--email"
        :title="capitalize('email')"
        :style="{backgroundImage: getSocialIcon('email')}"
        rel="noopener"
        :href="`mailto:${contact.email}`"
        target="_blank"></a>
      <a
        class="entity-contact__social"
        :title="capitalize(contact.platform)"
        :style="{backgroundImage: getSocialIcon(contact.platform)}"
        rel="noopener"
        :href="contact.url"
        target="_blank"></a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactComponent',
  props: {
    contact: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  methods: {
    capitalize(str) {
      const lowered = str.toLowerCase();
      return `${lowered.charAt(0).toUpperCase()}${lowered.slice(1)}`;
    },
    getSocialIcon(platform) {
      return `url(/img/icons/social/${platform.toLowerCase()}.svg)`;
    },
  },
};
</script>

<style lang="scss">
$icon-size: 24px;

.entity-contact {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  &__person {
  }
  &__name {
    font-weight: 600;
  }
  &__role {
  }
  &__socials {
    display: flex;
    flex: 0 0 auto;
  }
  &__social {
    display: block;
    height: $icon-size;
    width: $icon-size;
    cursor: pointer;
    background-size: calc(#{$icon-size} - 6px);
    background-position: center center;
    background-repeat: no-repeat;
    margin-right: 2px;
    transition: background-color 250ms ease;
    background-color: transparent;
    border-radius: 100%;
    &:hover {
      background-color: #edf1f7;
    }
  }
}
</style>
