<template>
  <section class="entity-section entity-section--entpri">

    <BlockSubtitle modal="prioritary-sdgs">{{$t('entity.priority_sdg')}}</BlockSubtitle>

    <div v-if="report.goals?.length > 0" class="entpri__odslist">
      <div v-for="(goal, i) in filterGoals(report.goals)" :key="i" class="entpri__ods">
        <router-link :to="{name: 'home', query: {sdg: goal.global_goal_code}, params: { locale: $i18n.locale }}">
          <img
            :src="`/img/${$i18n.locale}/sdg/${goal.global_goal_code}.png`"
            :alt="$t(`sdg.${goal.global_goal_code}`)"
            :title="$t(`sdg_name.${goal.global_goal_code}`)">
        </router-link>
      </div>
    </div>

    <Warn v-else type="info">{{$t('error.entity_has_no_data')}}</Warn>

  </section>
</template>

<script>
export default {
  name: 'EntityPriorityODSComponent',
  props: {
    report: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  methods: {
    filterGoals(goals) {
      return goals.filter((goal) => goal.global_goal_type === 'GOAL');
    },
  },
};

</script>

<style lang="scss">
.entity-section--entpri {
  margin-top: 10px;
}
.entpri {
  &__odslist {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 20px auto 0;
  }
  &__ods {
    flex: 0 1 120px;
    img {
      width: 100%;
      height: auto;
      padding: 4px;
      box-sizing: border-box;
    }
  }
}

@media screen and (min-width: 396px) {
  .entity-section--entpri {
    margin-top: 20px;
  }
  .entpri {
    h2 {
      text-align: center;
    }
  }
}
</style>
